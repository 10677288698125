export const localeHomePageLinks = {
  es: [
    {
      anchor: "Paletas de color azul",
      slug: "/azul/",
      color: "#2196F3",
    },
    {
      anchor: "Paletas de color verde",
      slug: "/verde/",
      color: "#009929",
    },
    {
      anchor: "Paletas de color amarillo",
      slug: "/amarillo/",
      color: "#ffff00",
    },
    {
      anchor: "Paletas de color rosa",
      slug: "/rosa/",
      color: "#ff69b4",
    },
    {
      anchor: "Paletas de color morado",
      slug: "/morado/",
      color: "#7f00b2",
    },
    {
      anchor: "Paletas de color rojo",
      slug: "/rojo/",
      color: "#ff0000",
    },
    {
      anchor: "Paletas de color gris",
      slug: "/grises/",
      color: "#666666",
    },
    {
      anchor: "Paletas de color magenta",
      slug: "/magenta/",
      color: "#e5097f",
    },
    {
      anchor: "Paletas de color pastel",
      slug: "/pastel/",
      color: "#f7cae4",
    },
    {
      anchor: "Paletas de colores fríos",
      slug: "/frios/",
      color: "#15ab92",
    },
    {
      anchor: "Paletas de colores cálidos",
      slug: "/calidos/",
      color: "#f2994A",
    },
    {
      anchor: "Paletas de colores neón",
      slug: "/neon/",
      color: "#ff0571",
    },
    {
      anchor: "Paletas de colores oscuros",
      slug: "/oscuros/",
      color: "#082032",
    },
    {
      anchor: "Paletas de colores vintage",
      slug: "/vintage/",
      color: "#ede6db",
    },
    {
      anchor: "Paletas de colores minimalista",
      slug: "/minimalista/",
      color: "#a1d9d9",
    },
    {
      anchor: "Paletas de colores vivos",
      slug: "/vivos/",
      color: "#00e676",
    },
  ],
  pt: [
    {
      anchor: "Paletas de cores azul",
      slug: "/pt/azul/",
      color: "#2196F3",
    },
    {
      anchor: "Paletas de cores verde",
      slug: "/pt/verde/",
      color: "#009929",
    },
    {
      anchor: "Paletas de cores amarelo",
      slug: "/pt/amarelo/",
      color: "#ffff00",
    },
    {
      anchor: "Paletas de cores rosa",
      slug: "/pt/cor-rosa/",
      color: "#ff69b4",
    },
    {
      anchor: "Paletas de cores roxa",
      slug: "/pt/roxa/",
      color: "#7f00b2",
    },
    {
      anchor: "Paletas de cores vermelho",
      slug: "/pt/vermelho/",
      color: "#ff0000",
    },
    {
      anchor: "Paletas de cores cinza",
      slug: "/pt/cinza/",
      color: "#666666",
    },
    {
      anchor: "Paletas de cores magenta",
      slug: "/pt/magenta/",
      color: "#e5097f",
    },
    {
      anchor: "Paletas de cores pastel",
      slug: "/pt/cores-pastel/",
      color: "#f7cae4",
    },
    {
      anchor: "Paletas de cores frias",
      slug: "/pt/frias/",
      color: "#15ab92",
    },
    {
      anchor: "Paletas de cores quentes",
      slug: "/pt/quentes/",
      color: "#f2994A",
    },
    {
      anchor: "Paletas de cores neon",
      slug: "/pt/cores-neon/",
      color: "#ff0571",
    },
    {
      anchor: "Paletas de cores escuras",
      slug: "/pt/escuras/",
      color: "#082032",
    },
    {
      anchor: "Paletas de cores vintage",
      slug: "/pt/vintage/",
      color: "#ede6db",
    },
  ],
  fr: [
    {
      anchor: "Palette de couleur bleu",
      slug: "/fr/bleu/",
      color: "#2196F3",
    },
    {
      anchor: "Palette de couleur vert",
      slug: "/fr/vert/",
      color: "#009929",
    },
    {
      anchor: "Palette de couleur jaune",
      slug: "/fr/jaune/",
      color: "#ffff00",
    },
    {
      anchor: "Palette de couleur rose",
      slug: "/fr/rose/",
      color: "#ff69b4",
    },
    {
      anchor: "Palette de couleur violet",
      slug: "/fr/violet/",
      color: "#7f00b2",
    },
    {
      anchor: "Palette de couleur rouge",
      slug: "/fr/rouge/",
      color: "#ff0000",
    },
    {
      anchor: "Palette de couleur gris",
      slug: "/fr/gris/",
      color: "#666666",
    },
    {
      anchor: "Palette de couleur magenta",
      slug: "/fr/magenta/",
      color: "#e5097f",
    },
    {
      anchor: "Palette de couleur pastel",
      slug: "/fr/pastel/",
      color: "#f7cae4",
    },
    {
      anchor: "Palette de couleur froide",
      slug: "/fr/froide/",
      color: "#15ab92",
    },
    {
      anchor: "Palette de couleur chaudes",
      slug: "/fr/chaudes/",
      color: "#f2994A",
    },
    {
      anchor: "Palette de couleur néon",
      slug: "/fr/neon/",
      color: "#ff0571",
    },
    {
      anchor: "Palette de couleur sombre",
      slug: "/fr/sombre/",
      color: "#082032",
    },
    {
      anchor: "Palette de couleur vintage",
      slug: "/fr/vintage/",
      color: "#ede6db",
    },
    {
      anchor: "Palette de couleur minimaliste",
      slug: "/fr/minimaliste/",
      color: "#a1d9d9",
    },
    {
      anchor: "Palette de couleur vives",
      slug: "/fr/vives/",
      color: "#00e676",
    },
  ],
};
