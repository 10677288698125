import React, { useState, useRef, useEffect } from "react";
import chroma from "chroma-js";
import html2canvas from "html2canvas";
import "./generator.css";
import { darkOrLightColor } from "../../helpers/darkOrLightColor";
import AdManaWithPlaceholder from "../Ads/AdManaWithPlaceHolder";

// Función auxiliar para convertir RGB a CMYK (retorna array de valores 0-100)
function rgbToCmyk(r, g, b) {
  let c = 1 - r / 255;
  let m = 1 - g / 255;
  let y = 1 - b / 255;
  let k = Math.min(c, m, y);

  c = (c - k) / (1 - k) || 0;
  m = (m - k) / (1 - k) || 0;
  y = (y - k) / (1 - k) || 0;

  return [
    Math.round(c * 100),
    Math.round(m * 100),
    Math.round(y * 100),
    Math.round(k * 100),
  ];
}

const ColorPaletteGenerator = ({ lang = "es" }) => {
  // ----------------------------
  // Estados para la paleta y el historial (Undo/Redo)
  // ----------------------------
  const [palette, setPalette] = useState([]); // paleta actual
  const [history, setHistory] = useState([]); // historial de paletas
  const [historyIndex, setHistoryIndex] = useState(-1); // índice en el historial

  // Formato de color seleccionado
  const [colorFormat, setColorFormat] = useState("hex");

  // Referencia al contenedor oculto para la descarga de PNG
  const hiddenPaletteRef = useRef(null);

  // ----------------------------
  // Generar nueva paleta
  // ----------------------------
  const generateNewPalette = () => {
    // Ejemplo: generamos 5 colores aleatorios con chroma
    // (puedes modificar la lógica de generación a tu gusto)
    const newPalette = Array.from({ length: 5 }, () => chroma.random().hex());

    // Actualizar el historial
    const newHistory = history.slice(0, historyIndex + 1);
    newHistory.push(newPalette);
    setHistory(newHistory);
    setHistoryIndex(historyIndex + 1);

    setPalette(newPalette); // Actualizar la paleta
  };

  // ----------------------------
  // Undo y Redo
  // ----------------------------

  const handleUndo = () => {
    if (historyIndex > 0) {
      const newIndex = historyIndex - 1;
      setHistoryIndex(newIndex);
      setPalette(history[newIndex]);
    }
  };

  const handleRedo = () => {
    if (historyIndex < history.length - 1) {
      const newIndex = historyIndex + 1;
      setHistoryIndex(newIndex);
      setPalette(history[newIndex]);
    }
  };

  // ----------------------------
  // Copiar color al portapapeles
  // ----------------------------
  const copyColorToClipboard = colorValue => {
    navigator.clipboard.writeText(colorValue).then(
      () => {
        alert(`Color copiado: ${colorValue}`);
      },
      err => {
        console.error("Error al copiar: ", err);
      }
    );
  };

  // ----------------------------
  // Cambio de formato
  // ----------------------------
  const handleFormatChange = e => {
    setColorFormat(e.target.value);
  };

  // Función para formatear el color según la selección
  const formatColor = (hexColor, format = colorFormat) => {
    const color = chroma(hexColor);

    switch (format) {
      case "hex":
        return color.hex(); // #RRGGBB
      case "rgb":
        const [r, g, b] = color.rgb();
        return `rgb(${r}, ${g}, ${b})`;
      case "hsl":
        const [h, s, l] = color.hsl();
        return `hsl(${Math.round(h)}, ${Math.round(s * 100)}%, ${Math.round(
          l * 100
        )}%)`;
      case "cmyk":
        const [c, m, y, k] = rgbToCmyk(...color.rgb());
        return `cmyk(${c}%, ${m}%, ${y}%, ${k}%)`;
      default:
        return color.hex();
    }
  };

  // ----------------------------
  // Descargar paleta en PNG
  // ----------------------------
  const handleDownloadPNG = () => {
    if (!hiddenPaletteRef.current) return;

    // Usamos html2canvas para capturar la sección oculta
    html2canvas(hiddenPaletteRef.current).then(canvas => {
      const dataURL = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.download = "palette.png";
      link.href = dataURL;
      link.click();
    });
  };

  // ----------------------------
  // Inicializar una paleta al cargar
  // ----------------------------
  useEffect(() => {
    generateNewPalette();
  }, []); // Se ejecuta solo una vez al montar el componente

  const h1Text =
    lang === "es"
      ? "Generador de Paletas de colores online"
      : lang === "en"
      ? "Online color palette generator"
      : lang === "pt"
      ? "Gerador de paletas de cores online"
      : lang === "fr"
      ? "Générateur de palettes de couleurs en ligne"
      : null;

  const toolbarText = {
    generate:
      lang === "es"
        ? "Generar"
        : lang === "en"
        ? "Generate"
        : lang === "pt"
        ? "Gerar"
        : lang === "fr"
        ? "Générer"
        : null,
    undo:
      lang === "es"
        ? "Deshacer"
        : lang === "en"
        ? "Undo"
        : lang === "pt"
        ? "Desfazer"
        : lang === "fr"
        ? "Annuler"
        : null,
    redo:
      lang === "es"
        ? "Rehacer"
        : lang === "en"
        ? "Redo"
        : lang === "pt"
        ? "Refazer"
        : lang === "fr"
        ? "Refaire"
        : null,
    download:
      lang === "es"
        ? "Descargar"
        : lang === "en"
        ? "Download"
        : lang === "pt"
        ? "Baixar"
        : lang === "fr"
        ? "Télécharger"
        : null,
  };

  const sampleText = {
    heading:
      lang === "es"
        ? "Ejemplo de un encabezado"
        : lang === "en"
        ? "Header example"
        : lang === "pt"
        ? "Exemplo de um cabeçalho"
        : lang === "fr"
        ? "Exemple de titre"
        : null,
    paragraph:
      lang === "es"
        ? "Este es un párrafo de ejemplo para mostrar cómo se vería el texto en un contexto real."
        : lang === "en"
        ? "This is a paragraph example to show how the text would look in a real context."
        : lang === "pt"
        ? "Este é um parágrafo de exemplo para mostrar como o texto seria em um contexto real."
        : lang === "fr"
        ? "Ceci est un paragraphe d'exemple pour montrer comment le texte apparaîtrait dans un contexte réel."
        : null,
    button:
      lang === "es"
        ? "Botón de ejemplo"
        : lang === "en"
        ? "Example button"
        : lang === "pt"
        ? "Botão de exemplo"
        : lang === "fr"
        ? "Bouton d'exemple"
        : null,
  };

  const watermarkText =
    lang === "es"
      ? "paleta creada con paletadecolores.online"
      : lang === "en"
      ? "created with paletadecolores.online/en/"
      : lang === "pt"
      ? "paleta criada com paletadecolores.online/pt/"
      : lang === "fr"
      ? "créé avec paletadecolores.online/fr/"
      : null;

  // ----------------------------
  // Render
  // ----------------------------
  return (
    <>
      <div className="palette-generator-container">
        <h1 className={"main-title"}>{h1Text}</h1>
        <div
          style={{
            backgroundColor: "#aaccdd",
            background: `linear-gradient(to right, ${palette[0]} 0%, ${palette[1]} 30%, ${palette[2]} 60%, ${palette[3]} 90%, ${palette[4]} 100%)`,
            height: "4px",
            width: "100%",
            marginBottom: "1rem",
          }}
        ></div>

        {/* Sección con los colores y sus formatos */}
        <div className="palette-colors">
          {palette.map((colorHex, idx) => {
            // Obtenemos los valores en distintos formatos
            const colorChroma = chroma(colorHex);
            const colorHexFormatted = colorChroma.hex();
            const [r, g, b] = colorChroma.rgb();
            const [h, s, l] = colorChroma.hsl();
            const [c, m, y, k] = rgbToCmyk(r, g, b);

            return (
              <div
                onClick={() => copyColorToClipboard(formatColor(colorHex))}
                key={idx}
                className="color-card"
              >
                <div
                  className="color"
                  style={{ backgroundColor: colorHex }}
                ></div>
                <div className="color-code">{formatColor(colorHex)}</div>
              </div>
            );
          })}
        </div>

        {/* Barra de herramientas */}
        <div className="toolbar">
          <button onClick={handleUndo} disabled={historyIndex <= 0}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              width="24"
              height="24"
              stroke-width="2"
            >
              <path d="M9 11l-4 4l4 4m-4 -4h11a4 4 0 0 0 0 -8h-1"></path>
            </svg>
            <span className="button-text">{toolbarText.undo}</span>
          </button>
          <button
            className=""
            onClick={handleRedo}
            disabled={historyIndex >= history.length - 1}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              width="24"
              height="24"
              stroke-width="2"
            >
              <path d="M15 11l4 4l-4 4m4 -4h-11a4 4 0 0 1 0 -8h1"></path>
            </svg>
            <span className="button-text">{toolbarText.redo}</span>
          </button>
          <button onClick={generateNewPalette}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              width="24"
              height="24"
              stroke-width="2"
            >
              <path d="M4 12v-3a3 3 0 0 1 3 -3h13m-3 -3l3 3l-3 3"></path>
              <path d="M20 12v3a3 3 0 0 1 -3 3h-13m3 3l-3 -3l3 -3"></path>
            </svg>
            <span className="button-text">{toolbarText.generate}</span>
          </button>
          <button onClick={handleDownloadPNG}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              width="24"
              height="24"
              stroke-width="2"
            >
              <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2"></path>
              <path d="M7 11l5 5l5 -5"></path>
              <path d="M12 4l0 12"></path>
            </svg>
            <span className="button-text">{toolbarText.download}</span>
          </button>
          <select value={colorFormat} onChange={handleFormatChange}>
            <option value="hex">HEX</option>
            <option value="rgb">RGB</option>
            <option value="hsl">HSL</option>
            <option value="cmyk">CMYK</option>
          </select>
        </div>

        <AdManaWithPlaceholder
          path="/22250597679/BloquesWortise/BloquesWortise_Content3"
          size={[
            [300, 250],
            [970, 250],
            [728, 250],
            [970, 90],
            [728, 90],
          ]}
          id="Content3"
        />

        {/* Card con variables CSS */}
        <div className="css-variables-card">
          <h3>Variables CSS</h3>
          <pre>
            :root {"{\n"}
            {palette.map((colorHex, idx) => {
              return `  --color-${idx + 1}: ${chroma(colorHex).hex()};\n`;
            })}
            {"}"}
          </pre>
        </div>

        {/* Vista previa de la UI usando la paleta */}
        <div className="ui-preview">
          <h3 style={{ color: palette[0] }}>{sampleText.heading}</h3>
          <p style={{ color: palette[1] }}>{sampleText.paragraph}</p>
          <button style={{ backgroundColor: palette[2], color: "#fff" }}>
            {sampleText.button}
          </button>
        </div>

        {/* Contenedor oculto para la descarga de la paleta en PNG */}
        <div
          className="hidden-palette"
          ref={hiddenPaletteRef}
          style={{ display: "" }}
        >
          <div style={{ padding: "10px", background: "#fff" }}>
            <div style={{ display: "flex", gap: "8px" }}>
              {palette.map((colorHex, idx) => (
                <div key={idx} className="color-card-preview">
                  <div
                    className="color-preview"
                    style={{ backgroundColor: colorHex }}
                  ></div>
                  <div className="color-code-preview">
                    <ul>
                      <li>HEX: {formatColor(colorHex)}</li>
                      <li>{chroma(colorHex).css("rgb")}</li>
                      <li>{chroma(colorHex).css("hsl")}</li>
                      <li>{formatColor(colorHex, "cmyk")}</li>
                    </ul>
                  </div>
                </div>
              ))}
            </div>
            <p
              style={{
                marginTop: "4rem",
                fontSize: "14px",
                color: "#888",
                textAlign: "center",
              }}
            >
              <strong>{watermarkText}</strong>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ColorPaletteGenerator;
