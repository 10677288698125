import React from "react";
import {
  section,
  section_heading,
  home_palette_links,
  home_palette_links_link,
  home_palette_links_link_title,
} from "./styles.module.css";
import { darkOrLightColor } from "../../helpers/darkOrLightColor";

export default function LocalHomePageLinks({ heading, links }) {
  return (
    <section className={section}>
      <h2 className={section_heading}>{heading}</h2>
      <div className={home_palette_links}>
        {links.map(link => (
          <a
            key={link.slug}
            href={link.slug}
            className={home_palette_links_link}
            style={{ backgroundColor: link.color }}
          >
            <h3
              className={home_palette_links_link_title}
              style={{ color: darkOrLightColor(link.color) }}
            >
              {link.anchor}
            </h3>
          </a>
        ))}
      </div>
    </section>
  );
}
